<template>
	<div class=".gg-container-1">
	  <div class="search-container">
		<div
		  style="margin-left: auto; display: flex; justify-content: space-around"
		>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
			  @click="handleUpload(multipleSelectionPres[0])"
			  v-if="multipleSelectionPres.length == 1"
			  >上传
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('mul_upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
			  @click="handlerBatchUpload"
			  >批量上传
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-edit-outline"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
			  @click="handleEditTableHead"
			  >编辑表头
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
			  @click="handleResetSearch"
			  >重置查找
			</el-button>
		  </div>
		  <!--对应第三方图标的button样式-->
		</div>
	  </div>
	  <!----------------表格---------------->
	  <el-table
		border
		size="mini"
		v-loading="listLoading"
		:header-cell-style="{ 'text-align': 'center' }"
		:data="tableData"
		height="725"
		@selection-change="handleSelectionChange"
		style="width: 100%;z-index:0"
	  >
		<el-table-column type="selection" width="55" align="center" />
		<el-table-column type="index" align="center" width="50">
		</el-table-column>
		<template v-for="(column, index) in tableHead">
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-if="column.field_type === 'textBtn' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a
				style="font-size: 12px; color: #2379fb"
				@click.prevent="editArticle('view', scope.row)"
			  >
				{{ scope.row[column.column_prop] }}
			  </a>
			</template>
		  </el-table-column>
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.field_type === 'select' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <el-switch
				class="switch"
				@change="changeExamine(scope.row)"
				v-model="scope.row.status"
				:active-value="1"
				:inactive-value="0"
				active-text="启用"
				inactive-text="禁用"
				active-color="#13ce66"
				inactive-color="#C8C8C8"
			  >
			  </el-switch>
			</template>
		  </el-table-column>
		  <el-table-column
			:sortable="tableHeadSortable.includes(column.column_prop)"
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a v-if="column.column_prop == 'supervise_status'">
				{{ scope.row.supervise_status | formatSuperviseStatus }}
			  </a>
			  <a v-else>{{ scope.row[column.column_prop] }}</a>
			</template>
			<template #header>
			  <el-popover
				placement="bottom"
				title=""
				min-width="160"
				trigger="click"
				v-if="
				  searchTableHead.filter(
					(item) => item.name == column.column_prop
				  ).length > 0
				"
			  >
				<span slot="reference" class="search-header">
				  <span class="search-title">{{
					column.column_label_user_definition
					  ? column.column_label_user_definition
					  : column.column_label
				  }}</span>
				  <i
					style="margin-left: 3px;font-weight: bolder; font-size: 20px"
					class="el-icon-search"
					:class="{
					  isSearch: searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					  'el-icon-zoom-in': searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					}"
				  />
				</span>
				<el-date-picker
				  v-if="'created_at' == column.column_prop"
				  v-model="datetimerange"
				  type="daterange"
				  size="mini"
				  value-format="yyyy-MM-dd"
				  range-separator="至"
				  start-placeholder="开始日期"
				  end-placeholder="结束日期"
				  @change="dateChange"
				>
				</el-date-picker>
				<el-select
				  v-if="'supervise_status' == column.column_prop"
				  style="width: 200px"
				  @change="
					handleSearch(
					  column.column_prop,
					  searchParams.supervise_status
					)
				  "
				  v-model="searchParams.supervise_status"
				  clearable
				  placeholder="请选择"
				  size="small"
				>
				  <el-option
					v-for="item in supervise_status"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  >
				  </el-option>
				</el-select>
				<el-input
				  v-if="'pres_no' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.pres_no"
				  clearable
				  @change="handleSearch(column.column_prop, searchParams.pres_no)"
				>
				</el-input>
				<el-input
				  v-if="'doctors_name' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.doctors_name"
				  clearable
				  @change="
					handleSearch(column.column_prop, searchParams.doctors_name)
				  "
				>
				</el-input>
				<el-input
				  v-if="'first_trial_name' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.trial_name"
				  clearable
				  @change="
					handleSearch(
					  column.column_prop,
					  searchParams.first_trial_name
					)
				  "
				>
				</el-input>
			  </el-popover>
			  <span v-else>{{
				column.column_label_user_definition
				  ? column.column_label_user_definition
				  : column.column_label
			  }}</span>
			</template>
		  </el-table-column>
		</template>
	  </el-table>
  
	  <!----------------分页---------------->
	  <!--<pagination :total="total" :page.sync="listQuery.page" -->
	  <!--						:limit.sync="listQuery.limit"-->
	  <!--						@pagination="getList"/>-->
  
	  <pagination
		:total="total"
		:page.sync="listQuery.page"
		:limit.sync="listQuery.limit"
		@pagination="getList"
	  />
	  <!--编辑表头-->
	  <editTableHead
		:isActiveEditTableHead.sync="isActiveEditTableHead"
		@_getAdminFieldIndex="_getAdminFieldIndex"
		v-if="isActiveEditTableHead"
		@getList="getList"
    :table_type="table_type"
	  >
	  </editTableHead>
	</div>
  </template>
  
  <script>
  import editTableHead from "@/components/editTableHead/editTableHead";
  import { getAdminFieldIndex } from "@/api/drugs2.0";
  import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
  import {
	getPresDeliveryList,
	putPresDeliveryBatchSupervise,
  } from "@/api/informationSearch";
  import { mapState } from "vuex";
  export default {
	name: "flowSupervise",
	components: { Pagination, editTableHead },
	async created() {
	  await this._getAdminFieldIndex();
	  this.getList();
	  const res = await getPresDeliveryList();
	  console.log(res);
	},
	mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  computed:{
	...mapState({
		table_options: (state) => state.user.table_options,
	})
  },
	data() {
	  return {
      table_type:'presDelivery',
		operateList: [],
		isActiveEditTableHead: false,
		multipleSelectionPres: [],
		activities: [],
		tableHead: [],
		searchTableHead: [
		  //控制搜索
		  {
			name: "pres_no",
			isSearch: false,
		  },
		  {
			name: "first_trial_name",
			isSearch: false,
		  },
		  {
			name: "supervise_status",
			isSearch: false,
		  },
		  {
			name: "doctors_name",
			isSearch: false,
		  },
		],
		tableHeadSortable: [
		  //控制排序
		  "created_at",
		  "expired_at",
		  "next_visit_at",
		  "end_visit_at",
		  "end_order_at",
		],
		supervise_status: [
		  {
			value: "1",
			label: "未上传",
		  },
		  {
			value: "2",
			label: "待上传",
		  },
		  {
			value: "3",
			label: "已上传",
		  },
		],
		tableData: [],
		value: "",
		searchParams: {
		  orderTime: [],
		},
		total: 0,
		listQuery: {
		  page: 1,
		  limit: 10,
		  importance: undefined,
		  title: undefined,
		  type: undefined,
		  sort: "+id",
		},
		multipleSelection: "",
		listLoading: false,
	  };
	},
	filters: {
	  formatSuperviseStatus(v) {
		// 监管状态(1=未上传2=待上传3已上传)
		if (v === 1) {
		  return "未上传";
		} else if (v === 2) {
		  return "待上传";
		} else {
		  return "已上传";
		}
	  },
	},
	methods: {
	  handleSelectionChange(val) {
		this.multipleSelection = [];
		// this.multipleSelection = val;
		this.multipleSelection = val.map((v, k, a) => v.delivery_id);
		console.log(val, "val");
		this.multipleSelectionPres = [];
		val.forEach((item) => {
		  this.multipleSelectionPres.push(item);
		});
	  },
	  handleSearch(prop, value) {
		console.log(prop, value, "2312343");
		this.searchTableHead.forEach((item) => {
		  if (item.name == prop) {
			if (value == "") {
			  item.isSearch = false;
			} else {
			  item.isSearch = true;
			}
		  }
		});
		this.getList("restPage");
	  },
	  handleResetSearch() {
		this.searchParams = {
		  is_wx: "",
		  visit_level: "",
		  buy_count: "",
		};
		this.searchTableHead.forEach((item) => {
		  item.isSearch = false;
		});
		this.choiceDateCreateRecord = [];
		this.choiceDateExpire = [];
		this.choiceDateEndVisit = [];
		this.choiceDateEndOrder = [];
		this.choiceDateNextVisit = [];
		this.getList("restPage");
	  },
	  handleEditTableHead() {
		this.isActiveEditTableHead = true;
	  },
	  async _getAdminFieldIndex() {
		// try {
		//   this.listLoading = true;
		//   var params = {
		// 	type: "presDelivery",
		//   };
		//   const res = await getAdminFieldIndex(params);
		  this.tableHead = this.table_options.presDelivery;
		//   console.log(this.tableHead);
		// } catch (err) {
		//   //在此处理错误
		// } finally {
		//   this.listLoading = false;
		// }
	  },
	  handlerBatchUpload() {
		if (this.multipleSelection.length === 0) {
		  return this.$message({
			type: "warning",
			message: "请选择需要上传的处方流转!",
		  });
		}
  
		const params = {
		  ids: JSON.stringify(this.multipleSelection),
		};
		this.$confirm("确认批量上传处方流转?", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			putPresDeliveryBatchSupervise(params).then((res) => {
			  if (res.code === "200") {
				this.getList();
				this.$message({
				  type: "success",
				  message: "批量上传成功!",
				});
			  }
			});
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消删除",
			});
		  });
	  },
	  getList(rest) {
		if (rest == "restPage") {
		  this.listQuery.page = 1;
		}
		this.listLoading = true;
		let params = { ...this.searchParams };
		params.page = this.listQuery.page;
		params.page_size = this.listQuery.limit;
		getPresDeliveryList(params)
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  let _data = data.data;
			  this.total = _data.total;
			  this.listQuery.limit = _data.per_page;
			  this.tableData = _data.list;
			}
			this.listLoading = false;
		  })
		  .catch((error) => {
			console.log(error);
			this.listLoading = false;
		  });
	  },
	  cellStyle(row, column, rowIndex, columnIndex) {
		// if(row.column.label==="处方编号"){
		//   return 'color: #4ebafa'
		// }
	  },
	  handleUpload(row) {
		console.log(row);
		const params = {
		  ids: JSON.stringify([row.delivery_id]),
		};
		this.$confirm("确认上传处方流转?", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			putPresDeliveryBatchSupervise(params).then((res) => {
			  if (res.code === "200") {
				this.getList();
				this.$message({
				  type: "success",
				  message: "上传成功!",
				});
			  }
			});
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消删除",
			});
		  });
	  },
	},
  };
  </script>
  
  <style scoped lang="scss"></style>
  